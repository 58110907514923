import PropTypes from 'prop-types';
import cx from 'classnames';

import resolveRelationships from 'core/utils/relationships';
import getImageUrl from 'core/utils/smart-image';

import { isMotoPath, buildDealerUrl } from 'site/utils';
import getThumbnail from 'site/utils/get-thumbnail';

import PhoneIcon from 'site/icons/Phone';

import styles from './index.styl';


const requiredPayloadImports = ['car_brands', 'geo', 'dealer_network'];

const relationships = resolveRelationships(requiredPayloadImports, null, {
  dealer_network: {},
});

/**
 * В данном компоненте нельзя использовать компоненты из LB в которых используется context из-за того, что получаем ошибку
 * происходит все из-за того, что мы переводим компонент в текст, т.к. в яндекс картах используется нативный JS
 */


export default function Balloon(props, { webdavHost }) {
  const {
    dealer,
    geoSlug,
    location,
    match: {
      params: {
        brand: brandSlug,
      },
    },
  } = props;

  const {
    attributes: {
      description,
      address,
      phones,
      slug: dealerSlug,
      name,
      offers_count: offersCount,
    },
  } = dealer;

  const isMoto = isMotoPath(location.pathname);

  const {
    dealer_network: {
      image,
    },
  } = relationships(dealer);

  const dealerImage = getThumbnail(image);

  let imgUrl;

  if (dealerImage) {
    const widthImg = dealerImage.width / 2;
    const heightImg = dealerImage.height / 2;
    const src = { 'width': widthImg, 'height': heightImg, 'rel_url': dealerImage.rel_url };

    imgUrl = getImageUrl({
      src,
      maxWidth: widthImg,
      maxHeight: heightImg,
      webdavHost: webdavHost,
    });
  }

  const srcDealer = buildDealerUrl(geoSlug, brandSlug, dealerSlug, isMoto);

  const Wrapper = offersCount ? 'a' : 'div';

  return (
    <div className={cx(styles.balloon, offersCount && styles._withOffers)}>
      <Wrapper {...offersCount && { href: srcDealer }} className={styles.link}>
        <div className={styles.headerBalloon}>
          {
            dealerImage && <img
              className={styles.logoDealer}
              width={dealerImage.width / 2}
              height={dealerImage.height / 2}
              src={imgUrl}
            />
          }
          <h4 className={styles.title} >
            {name}
          </h4>
        </div>
        {description && <p>{description}</p>}
        {address && <div className={styles.address}>{address}</div>}
        {(phones?.length || null) &&
          <div className={styles.phoneWrapper}>
            {
              phones.map(({ phone, extension }) => (
                <div className={styles.phone} key={phone}>
                  <PhoneIcon className={styles.icon} />
                  {phone}
                  {extension ? `, доб. ${extension}` : null}
                </div>
              ))
            }
          </div>
        }
      </Wrapper>
    </div>
  );
}

Balloon.propTypes = {
  dealer: PropTypes.object,
  geoSlug: PropTypes.string,
  isMoto: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      brand: PropTypes.string,
    }),
  }),
};

Balloon.contextTypes = {
  webdavHost: PropTypes.string,
};
