import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'core/libs/helmet';
import resolveRelationships from 'core/utils/relationships';

import { withBreakpoint } from 'core/components/breakpoint';
import withPageHocs from 'core/components/withPageHocs';

import modelPropTypes, {
  dealerAttributes,
  offerAttributes,
  seoFullDataAttributes,
} from 'site/utils/prop-types/model';

import PageLayoutService from 'site/components/PageLayoutService';
import TitleBackward from 'site/components/TitleBackward';
import { Indent } from 'site/components/Wrappers';
import DealerInfo from 'site/components/DealerInfo';
import SecondaryMenu from 'site/components/SecondaryMenu';

import { isMotoPath } from 'site/utils';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';

import OffersList from './OffersList';

import dataProvider from './dealerDataProvider';


const relationships = resolveRelationships(['geo_parent'], null, {
  geo_parent: {},
});

function DealerPage(props) {
  const {
    dealer,
    dealer: {
      attributes: { name },
    },
    dealerSeo: {
      title: seoTitle,
      description: seoDescription,
    },
    offersData,
    offersData: {
      meta: {
        filtered_count: filteredCount,
      },
    },
    location,
    match: {
      params: {
        geo,
        brand,
      },
    },
    isMobile,
  } = props;

  const isMoto = isMotoPath(location.pathname);

  const {
    geo_parent: {
      url: parentGeo,
    },
  } = relationships(dealer);

  const backUrl = '/' + [isMoto && 'moto', 'dealers', parentGeo || geo, brand].filter(Boolean).join('/');
  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  return (
    <Fragment>
      <Helmet>
        <title>{seoTitle}</title>
        <meta name='description' content={seoDescription} />
        {filteredCount === 0 && <meta name='robots' content='noindex, nofollow' />}
      </Helmet>
      <SecondaryMenu section='dealer' />
      <PageLayoutService bgContentList>
        <TitleBackward
          url={backUrl}
          title={`${isMoto ? 'Мотосалон' : 'Автосалон'} "${name}"`}
        />
        <Indent bottom={vertical} />
        <DealerInfo content={dealer} isMoto={isMoto} />
        <Indent bottom={vertical} />
        {!isMoto &&
          <Fragment>
            <OffersList offersData={offersData} />
            <Indent bottom={vertical} />
          </Fragment>
        }
      </PageLayoutService>
    </Fragment>
  );
}

DealerPage.propTypes = {
  dealer: modelPropTypes(dealerAttributes),
  dealerSeo: modelPropTypes(seoFullDataAttributes).isRequired,
  offersData: PropTypes.shape({
    data: PropTypes.arrayOf(modelPropTypes(offerAttributes)),
    meta: PropTypes.shape({
      filtered_count: PropTypes.number,
    }),
  }),
  location: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      geo: PropTypes.string.isRequired,
      brand: PropTypes.string,
      dealer_slug: PropTypes.string.isRequired,
    }),
  }),
  isMobile: PropTypes.bool,
};

export default withBreakpoint(withPageHocs(dataProvider)(DealerPage));
